export default {
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítás"])},
  "newAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Admin"])},
  "modifyAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Módosítás"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "telnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
  "chooseAdminLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz admin szintet"])},
  "chooseAdminCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz cégeket"])},
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatok"])},
  "chooseCompanyGroop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz cégcsoportot"])},
  "newCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Cég"])},
  "modifyCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég Módosítás"])},
  "chooseCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz Céget"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Rendezvény"])},
  "modifyEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendezvény módosítása"])},
  "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz Rendezvényt"])},
  "newCompanyInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Cég-Rendezvény"])},
  "modifyCompanyInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég-Rendezvény módosítása"])},
  "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdés"])},
  "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vége"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogosultságok"])},
  "newPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kép feltöltése"])},
  "chooseRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz jogkört"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi jelszó"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
  "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó újra"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó megváltoztatása"])},
  "changeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatok módosítása"])},
  "notMatchingPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszavak nem egyeznek!"])},
  "shortPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszónak legalább 10 karakter hosszúnak kell lennie!"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég(ek)"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogosultság(ok)"])},
  "passwordforaccred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszó NEM fog megváltozni! Azért kell, hogy tudjuk tényleg Ön akarja az adatokat megváltoztatni!"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (Kötelező)"])},
  "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (Nem kötelező)"])},
  "superadmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Adminok"])},
  "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adminok"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégek"])},
  "companyGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégcsoportok"])},
  "companyInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégek Rendezvényeken"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendezvények"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogosultságok"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyszínek"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisztikák"])},
  "companyAdmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég Adminok"])},
  "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolgozók"])},
  "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolgozó"])},
  "accreditations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkreditációk"])},
  "printers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatók"])},
  "printing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatás"])},
  "printing2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatás2"])},
  "printing3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatás3"])},
  "enters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beléptetők"])},
  "entering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beléptetés"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítség"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilépés"])},
  " ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
  "newAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Admin"])},
  "adminLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Level"])},
  "adminRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator jogok"])},
  "logoutafterdatachange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres adatmódosítás esetén automatikusan ki leszel jelentkeztetve! Újra be kell jelentkezned, hogy adataid frissítve legyenek!"])},
  "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosít"])},
  "newCompanyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Cégcsoport"])},
  "companiesInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégek a Rendezvényeken"])},
  "newRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Jogosultság"])},
  "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Helyszín"])},
  "newPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új Dolgozó"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendezvény"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég"])},
  "companyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég Adatok"])},
  "companyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég Csoport"])},
  "companyGroupDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég Csoport Leírás"])},
  "publicRegLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános regisztrációs link másolása"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
  "roleCanUseAndUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogosultságok (Használható / Felhasznált)"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdés"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vége"])},
  "rolesCanBeUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használható jogosultságok"])},
  "hungarianDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar leírás"])},
  "englishDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angol leírás"])},
  "adminsCanProvide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adminok akik kiadhatják"])},
  "anyonecangive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindenki kiadhatja"])},
  "plsSelectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek válassz céget"])},
  "plsSelectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek válassz rendezvényt"])},
  "modifyCompanyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégcsoport módosítás"])},
  "modifyRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogosultság módosítás"])},
  "cantlowerthecanuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A használható jogosultság nem lehet kevesebb mint a már felhasznált!"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogosultság"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyva"])},
  "photouploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fénykép feltöltve"])},
  "accredNoPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dolgozónak nincs fényképe! Nem tudod jóváhagyni az akkreditációt fénykép nélkül!"])},
  "canUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használható"])},
  "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasznált"])},
  "companiesPublicRegLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégek publikus regisztrációi"])},
  "deleteSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan törölni akarod: "])},
  "questionmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
  "successfullSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres mentés"])},
  "successfullDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres törlés"])},
  "uploadexcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel feltöltés"])},
  "successfullUlpoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload success"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "underprinting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatás alatt"])},
  "notprinted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatásra vár"])},
  "printed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinyomtatva"])},
  "successfullRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres regisztráció"])},
  "publicReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikus regisztráció"])},
  "thisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a(z) "])},
  "companysRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cég publikus regisztrációs oldala."])},
  "sendnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó küldése"])},
  "newpasswordsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha találunk felhasználót a megadott email címmel, akkor perceken belül küldünk egy új jelszót a megadott email címre."])},
  "plsuploadpicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek töltsd fel a képet!"])},
  "underediting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés alatt"])},
  "helpdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addig ha segítségre lenne szükséged, kérlek olvasd el az alábbi dokumentumot!"])},
  "ifyourenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello! Ha új az oldalunkon kérjük látogasson el a segítség oldalra."])},
  "checkprinted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatás ellenőrzése"])},
  "printagain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újra nyomtatás"])},
  "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorszám"])},
  "tomuchroles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem tudod elmenteni ezt az akkreditációt, mert kifogytál a kiosztható jogokból: "])},
  "pleaseuploadnormalphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek először képet tölts fel magadról! A képnek állónak kell lennie, amin az arcod tisztán látszik! Igazolványképhez hasonlót!"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tisztítás"])},
  "swapname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kereszt és Vezetéknév cseréje"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
  "pleaselogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek jelentkezz be!"])},
  "forgottenpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfelejtett jelszó"])},
  "approvedbutnullrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem tud jóváhagyni akkreditációt jogosultság nélkül!"])},
  "deletepersonto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel nem csak az akkreditációt de a dolgozót is törli!"])},
  "deleteperson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolgozó törlése"])},
  "acceptaszf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Elolvastam és elfogadom az "])},
  "acceptaszf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adatkezelési tájékoztatóban "])},
  "acceptaszf3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" foglaltakat."])},
  "acceptaszf4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" A dolgozóval ismertettem az "])},
  "acceptaszf5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" foglaltakat, és a dolgozó elfogadta azokat."])},
  "karbantartas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az oldal nem üzemel. Vasárnap estig karbantartási munkálatokat végzünk."])},
  "cardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kártyaszám"])},
  "pickedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felvevő"])},
  "reprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újranyomtatás"])}
}