<template>
  <CHeader style="text-align: right" class="mb-4"> </CHeader>
</template>

<script>
//import AppBreadcrumb from './AppBreadcrumb'
//import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
//import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
//import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
//import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logo } from '@/assets/brand/logo'

export default {
  name: 'AppHeader',
  components: {
    //CIcon,
    //AppBreadcrumb,
    //AppHeaderDropdownAccnt,
    //AppHeaderDropdownMssgs,
    //AppHeaderDropdownNotif,
    //AppHeaderDropdownTasks,
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>

<style>
#navigation {
  position: absolute; /*or fixed*/
  right: 0px;
  float: right;
}
</style>
