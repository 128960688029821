import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: DefaultLayout,
    redirect: '/login',
    children: [
      {
        path: '/aszf',
        name: 'ASZF',
        component: () => import('@/views/pages/ASZF.vue'),
        meta: {
          hideNavbar: true,
        },
      },
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/pages/Home.vue'),
      },
      {
        path: '/admins',
        name: 'Admins',
        component: () => import('@/views/pages/Admins.vue'),
      },
      {
        path: '/admin/create',
        name: 'CreateAdmin',
        component: () => import('@/views/pages/create/CreateAdmin.vue'),
      },
      {
        path: '/admin/update/:id',
        name: 'UpdateAdmin',
        component: () => import('@/views/pages/create/CreateAdmin.vue'),
      },
      {
        path: '/accreditations',
        name: 'Accreditations',
        component: () => import('@/views/pages/Accreditations.vue'),
      },
      {
        path: '/locations',
        name: 'Locations',
        component: () => import('@/views/pages/Locations.vue'),
      },
      {
        path: '/location/create',
        name: 'CreateLocation',
        component: () => import('@/views/pages/create/CreateLocation.vue'),
      },
      {
        path: '/location/update/:id',
        name: 'UpdateLocation',
        component: () => import('@/views/pages/create/CreateLocation.vue'),
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: {
          hideNavbar: true,
        },
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('@/views/pages/Logout.vue'),
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('@/views/pages/Statistics.vue'),
      },
      {
        path: '/printing',
        name: 'Printing',
        component: () => import('@/views/pages/Printing.vue'),
      },
      {
        path: '/printing2',
        name: 'Printing2',
        component: () => import('@/views/pages/Printing2.vue'),
      },
      {
        path: '/printing3',
        name: 'Printing3',
        component: () => import('@/views/pages/Printing3.vue'),
      },
      {
        path: '/entering',
        name: 'Entering',
        component: () => import('@/views/pages/Entering.vue'),
      },
      {
        path: '/enter/:id',
        name: 'EnterLocation',
        component: () => import('@/views/pages/EnterLocation.vue'),
      },
      {
        path: '/help',
        name: 'Help',
        component: () => import('@/views/pages/Help.vue'),
      },
      {
        path: '/publicreg/:id',
        name: 'PublicRegistration',
        component: () => import('@/views/pages/PublicRegistration.vue'),
        meta: {
          hideNavbar: true,
        },
      },
      {
        path: '/companies',
        name: 'Companies',
        component: () => import('@/views/pages/Companies'),
      },
      {
        path: '/company/create',
        name: 'CreateCompanie',
        component: () => import('@/views/pages/create/CreateCompany'),
      },
      {
        path: '/company/update/:id',
        name: 'UpdateCompanie',
        component: () => import('@/views/pages/create/CreateCompany.vue'),
      },
      {
        path: '/companygroups',
        name: 'CompanyGroups',
        component: () => import('@/views/pages/CompanieGroups.vue'),
      },
      {
        path: '/companygroup/create',
        name: 'CreateCompanyGroup',
        component: () => import('@/views/pages/create/CreateCompanyGroup.vue'),
      },
      {
        path: '/companygroup/update/:id',
        name: 'UpdateCompanieGroup',
        component: () => import('@/views/pages/create/CreateCompanyGroup.vue'),
      },

      {
        path: '/companyinevent',
        name: 'CompanieInEvent',
        component: () => import('@/views/pages/CompaniesInEvent.vue'),
      },
      {
        path: '/events',
        name: 'Events',
        component: () => import('@/views/pages/Events'),
      },
      {
        path: '/event/create',
        name: 'CreateEvent',
        component: () => import('@/views/pages/create/CreateEvent'),
      },
      {
        path: '/event/update/:id',
        name: 'UpdateEvent',
        component: () => import('@/views/pages/create/CreateEvent'),
      },
      {
        path: '/persons',
        name: 'Persons',
        component: () => import('@/views/pages/Persons.vue'),
      },
      {
        path: '/person/create',
        name: 'CreatePerson',
        component: () => import('@/views/pages/create/CreatePerson.vue'),
      },
      {
        path: '/person/update/:id',
        name: 'UpdatePerson',
        component: () => import('@/views/pages/create/CreatePerson.vue'),
      },
      {
        path: '/roles',
        name: 'Roles',
        component: () => import('@/views/pages/Roles.vue'),
      },
      {
        path: '/role/create',
        name: 'CreateRole',
        component: () => import('@/views/pages/create/CreateRole.vue'),
      },
      {
        path: '/role/update/:id',
        name: 'UpdateRole',
        component: () => import('@/views/pages/create/CreateRole.vue'),
      },

      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/multi-select',
            name: 'Multi Select',
            component: () => import('@/views/forms/MultiSelect.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/smart-table',
        name: 'Smart Table',
        component: () => import('@/views/smart-table/SmartTable.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
