import axios from 'axios'

export default class Adminservice {
  sendmessage(message) {
    return axios.post('api/admin/sendmessage', { message: message })
  }
  async getAll() {
    return axios.get('api/admin/all')
  }

  async getById(id) {
    return axios.get('/api/admin/' + id)
  }

  async geCurrentAdmin() {
    return axios.get('/api/admin/currentadmin')
  }
  async getSuperadmins() {
    return axios.get('/api/admin/superadmins')
  }

  async getadminlevels() {
    return axios.get('/api/admin/adminlevels')
  }

  async getadminroles() {
    return axios.get('/api/admin/adminroles')
  }

  async create(admin) {
    return axios.post('/api/admin/create', admin)
  }

  async update(admin) {
    return axios.put('/api/admin/update', admin)
  }

  async updateDetails(name, email, telnumber, password) {
    return axios.put('/api/admin/changedetails', {
      name: name,
      email: email,
      telnumber: telnumber,
      password: password,
    })
  }

  async delete(adminId) {
    return axios.delete('/api/admin/delete/' + adminId)
  }

  async changePassword(currentPassword, newPassword) {
    return axios.put('/api/admin/changepassword', {
      currentPassword: currentPassword,
      newPassword: newPassword,
    })
  }
}
