<template>
  <AppSidebar v-if="!$route.meta.hideNavbar" />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader v-if="!$route.meta.hideNavbar" />
    <div class="body flex-grow-1 px-3">
      <router-view />
    </div>
  </div>
  <!--AppAside /-->
</template>
<script>
//import { CContainer } from '@coreui/vue-pro'
//import AppAside from '@/components/AppAside.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    //AppAside,
    AppSidebar,
    //CContainer,
  },
}
</script>
