export default {
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "changeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Details"])},
  "chooseAdminCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Admin companies"])},
  "chooseAdminLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Admin Level"])},
  "chooseCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Company"])},
  "chooseCompanyGroop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Company Group"])},
  "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Event"])},
  "chooseRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Role"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company/ Companies"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Date"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "modifyAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Admin"])},
  "modifyCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Company"])},
  "modifyCompanyInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Company-Event"])},
  "modifyEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Event"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "newAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Admin"])},
  "newCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Company"])},
  "newCompanyInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Company-Event"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Event"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "newPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password again"])},
  "newPicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Photo"])},
  "notMatchingPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are not matching!"])},
  "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (Not required)"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "passwordforaccred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password will NOT change! We need to know that you are you!"])},
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (Required)"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "shortPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Password! The password have to be atleast 10 character long!"])},
  "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Date"])},
  "telnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification"])},
  "superadmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super admins"])},
  "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admins"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "companyGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Groups"])},
  "companyInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company In Event"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "companyAdmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Admins"])},
  "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
  "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "accreditations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accreditations"])},
  "printers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printers"])},
  "printing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printing"])},
  "printing2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printing2"])},
  "printing3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printing3"])},
  "enters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enters"])},
  "entering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  " ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
  "adminLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Level"])},
  "adminRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Roles"])},
  "logoutafterdatachange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After a successful modification, you'll be automatically logged out! You have to login again to refresh your credentials!"])},
  "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
  "newCompanyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Company Group"])},
  "companiesInEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies In Event"])},
  "newRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Role"])},
  "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Location"])},
  "newPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Employee"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "companyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Details"])},
  "companyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Group"])},
  "companyGroupDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Group Details"])},
  "publicRegLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy public registration link"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "roleCanUseAndUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles (Can Use / Used)"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "rolesCanBeUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles Can Be Used"])},
  "hungarianDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian Description"])},
  "englishDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Description"])},
  "adminsCanProvide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admins can Provide"])},
  "anyonecangive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyone can provide"])},
  "plsSelectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Company"])},
  "plsSelectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Event"])},
  "modifyCompanyGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Company Group"])},
  "modifyRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Role"])},
  "cantlowerthecanuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Can Use value cannot be lower then Used!"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "photouploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Uploaded"])},
  "accredNoPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The employee does not have a photo! You can not approve an accreditation without a photo!"])},
  "canUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can Use"])},
  "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
  "companiesPublicRegLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies public registrations"])},
  "deleteSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete: "])},
  "questionmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
  "successfullSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully save"])},
  "successfullDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully delete"])},
  "uploadexcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload excel"])},
  "successfullUlpoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload success"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "underprinting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under printing"])},
  "notprinted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Printed"])},
  "printed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printed"])},
  "successfullRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfull Registration"])},
  "publicReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Registration"])},
  "thisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the "])},
  "companysRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" company's public registration page."])},
  "sendnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send new password"])},
  "newpasswordsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If we found a user with this email, we will send you a new password to the email address is a few minutes."])},
  "plsuploadpicture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload picture!"])},
  "underediting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under editing"])},
  "helpdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until then, if you need help, please read the following document!"])},
  "ifyourenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello! If you are new to our site, please visit the help page."])},
  "checkprinted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Printed"])},
  "printagain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print again"])},
  "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
  "tomuchroles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't save this accreditation, because you run out of roles: "])},
  "pleaseuploadnormalphoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a picture of yourself first! The picture must be vertical, showing your face clearly! Similar to a passport photo!"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "swapname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap First and LastName"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "pleaselogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in!"])},
  "forgottenpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten Password"])},
  "approvedbutnullrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't approve an accreditation without a role!"])},
  "deletepersonto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are deleting not just the accreditation, but the your employee's personal data too!"])},
  "deleteperson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Employee"])},
  "acceptaszf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I have read and accept the "])},
  "acceptaszf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Privacy Policy."])},
  "acceptaszf3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
  "acceptaszf4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have informed the employee about the "])},
  "acceptaszf5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the employee accepted."])},
  "karbantartas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The site is not working. We are carrying out maintenance work until Sunday evening."])},
  "cardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
  "pickedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picked up"])},
  "reprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprint"])}
}