export default [
  {
    component: 'CNavItem',
    name: 'Home',
    to: '/home',
    icon: 'cil-user',
  },
  {
    component: 'CNavTitle',
    name: 'superadmins',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'admins',
    to: '/admins',
    icon: 'cil-user',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'companies',
    to: '/companies',
    icon: 'cil-home',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'companyGroups',
    to: '/companygroups',
    icon: 'cil-factory',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'companyInEvent',
    to: '/companyinevent',
    icon: 'cil-spreadsheet',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'events',
    to: '/events',
    icon: 'cil-calendar',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'roles',
    to: '/roles',
    icon: 'cil-ban',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'locations',
    to: '/locations',
    icon: 'cil-flag-alt',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'statistics',
    to: '/statistics',
    icon: 'cil-chart',
    access: 'SUPERADMIN',
  },
  {
    component: 'CNavTitle',
    name: 'companyAdmins',
    access: 'COMPANYADMIN',
    access2: 'SUPERADMIN',
    access3: 'PRINTER',
  },
  {
    component: 'CNavItem',
    name: 'persons',
    to: '/persons',
    icon: 'cil-user',
    access: 'COMPANYADMIN',
    access2: 'SUPERADMIN',
    access3: 'PRINTER',
  },
  {
    component: 'CNavItem',
    name: 'accreditations',
    to: '/accreditations',
    icon: 'cil-address-book',
    access: 'COMPANYADMIN',
    access2: 'SUPERADMIN',
    access3: 'PRINTER',
  },
  {
    component: 'CNavTitle',
    name: 'printers',
    access: 'PRINTER',
    access2: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'printing',
    to: '/printing',
    icon: 'cil-print',
    access: 'PRINTER',
    access2: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'printing2',
    to: '/printing2',
    icon: 'cil-print',
    access: 'PRINTER',
    access2: 'SUPERADMIN',
  },
  {
    component: 'CNavTitle',
    name: 'enters',
    access: 'LOCATION_CONTROLLER',
    access2: 'SUPERADMIN',
  },
  {
    component: 'CNavItem',
    name: 'entering',
    to: '/entering',
    icon: 'cil-center-focus',
    access: 'LOCATION_CONTROLLER',
    access2: 'SUPERADMIN',
  },
  {
    component: 'CNavTitle',
    name: '',
  },
  {
    component: 'CNavItem',
    name: 'help',
    to: '/help',
    icon: 'cil-info',
  },
  {
    component: 'CNavItem',
    name: 'logout',
    to: '/logout',
    icon: 'cil-exit-to-app',
  },
  /*,
  {
    component: 'CNavGroup',
    name: 'Base',
    to: '/base',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Popovers',
        to: '/base/popovers',
      },
      {
        component: 'CNavItem',
        name: 'Spinners',
        to: '/base/spinners',
      },
      {
        component: 'CNavItem',
        name: 'Tables',
        to: '/base/tables',
      },
      {
        component: 'CNavItem',
        name: 'Tooltips',
        to: '/base/tooltips',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Forms',
    to: '/forms',
    icon: 'cil-notes',
    items: [
      {
        component: 'CNavItem',
        name: 'Form Control',
        to: '/forms/form-control',
      },
      {
        component: 'CNavItem',
        name: 'Select',
        to: '/forms/select',
      },
      {
        component: 'CNavItem',
        name: 'Multi Select',
        to: '/forms/multi-select',
        badge: {
          color: 'danger',
          text: 'PRO',
        },
      },
      {
        component: 'CNavItem',
        name: 'Checks & Radios',
        to: '/forms/checks-radios',
      },
      {
        component: 'CNavItem',
        name: 'Range',
        to: '/forms/range',
      },
      {
        component: 'CNavItem',
        name: 'Input Group',
        to: '/forms/input-group',
      },
      {
        component: 'CNavItem',
        name: 'Floating Labels',
        to: '/forms/floating-labels',
      },
      {
        component: 'CNavItem',
        name: 'Layout',
        to: '/forms/layout',
      },
      {
        component: 'CNavItem',
        name: 'Validation',
        to: '/forms/validation',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Notifications',
    to: '/notifications',
    icon: 'cil-bell',
    items: [
      {
        component: 'CNavItem',
        name: 'Alerts',
        to: '/notifications/alerts',
      },
      {
        component: 'CNavItem',
        name: 'Modals',
        to: '/notifications/modals',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Smart Table',
    to: '/smart-table',
    icon: 'cil-grid',
    badge: {
      color: 'danger',
      text: 'PRO',
    },
  },/**/
]
