import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink } from 'vue-router'
import Adminservice from '@/services/adminservice'
import {
  CBadge,
  CNavGroup,
  CNavItem,
  CNavTitle,
  CSidebarNav,
} from '@coreui/vue-pro'
import nav from '@/_nav.js'
import i18n from '@/i18n'

const adminService = new Adminservice()

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    adminService.geCurrentAdmin()
    //const route = useRoute()
    const firstRender = ref(true)

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      var access1 = localStorage.getItem('level').includes(item.access)
      var access2 = localStorage.getItem('level').includes(item.access2)
      var access3 = localStorage.getItem('level').includes(item.access3)
      var access4 =
        item.access === undefined &&
        item.access2 === undefined &&
        item.access3 === undefined
      if (access1 || access2 || access3 || access4) {
        return item.to
          ? h(
              RouterLink,
              {
                to: item.to,
                custom: true,
              },
              {
                default: (props) =>
                  h(
                    resolveComponent(item.component),
                    {
                      active: props.isActive,
                      href: props.href,
                      onClick: () => props.navigate(),
                    },
                    {
                      default: () => [
                        item.icon &&
                          h(resolveComponent('CIcon'), {
                            customClassName: 'nav-icon',
                            name: item.icon,
                          }),
                        i18n.global.t(item.name),
                        item.badge &&
                          h(
                            CBadge,
                            {
                              class: 'ms-auto',
                              color: item.badge.color,
                            },
                            {
                              default: () => item.badge.text,
                            },
                          ),
                      ],
                    },
                  ),
              },
            )
          : h(
              resolveComponent(item.component),
              {},
              {
                default: () => i18n.global.t(item.name),
              },
            )
      }
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      )
  },
})
export { AppSidebarNav }
